import Vue from 'vue';
import Router from 'vue-router';
import { BaseRouter } from './config';

Vue.use(Router)
export default new Router({
    mode: 'history',
    base: 'http://localhost:8080',
    scrollBehavior: ()=>({y:0}),
    routes: BaseRouter
});