
// import layout from '@/components/layout';
import mulu from '@/components/MuLu.vue'
export const BaseRouter = [
    {
        path: '/jlga',
        name: 'dashboard',
        component: mulu,
        meta: {title: '首页'},
        children: [
            
        ]
    },
    {
        path: '/jlga/ConTent',
        name: 'ConTent',
        component: () => import('@/components/ConTent.vue')
    },
    // {
    //     path: '/jlga/2',
    //     name: '2',
    //     component: () => import('@/components/DiEr.vue')
    // },
    // {
    //     path: '/jlga/3',
    //     name: '3',
    //     component: () => import('@/components/DiSan.vue')
    // },
];