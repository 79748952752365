import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Storage from 'vue-ls'
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
// import mulu from '@/components/MuLu.vue'
// import DiYi from '@/components/DiYi.vue'
// import DiEr from '@/components/DiEr.vue'
// import DiSan from '@/components/DiSan.vue'

Vue.use(Element)
Vue.use(Storage, {
  namespace: 'pro__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
})
// const routes = {
//   '/mulu': mulu,
//   '/DiYi': DiYi,
//   '/DiEr': DiEr,
//   '/DiSan': DiSan,
// }

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
