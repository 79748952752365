<template>
    <div class="mulu">
        <!-- <h1>目录</h1> -->
        <div style="height: 40px;"></div>
        <div class="mulu_card">
            <div class="logo_left">走进校园政策汇总</div>
            <img :src="require('@/assets/pic_all_74.svg')" class="logo_right">
            <div class="mulu_body">
                <div class="item" @click="toPath('1')">1.落户政策 ></div>
                <div class="item" @click="toPath('2')">2.网上办理业务 ></div>
                <div class="item" @click="toPath('3')">3.全省通办、跨省通办业务 ></div>
                <div class="item" @click="toPath('4')">4.关于促进高校毕业生就业创业若干措施 ></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    methods: {
        toPath(to) {
            this.$store.commit('PUSH_DATA', to)
            this.$router.push("/jlga/ConTent")
        }
    }
}
</script>
<style>
.mulu {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}
.mulu_card {
    background-image: linear-gradient(rgba(144, 204, 255, 1), rgba(225, 241, 255, 1));
    margin: 12px;
    border-radius: 12px;
    padding: 46px 4px 4px 4px;
    position: relative;
}
.logo_left {
    background-image: linear-gradient(to right, rgba(43, 124, 244, 1), rgba(106, 177, 249, 1));
    border-top-left-radius: 8px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 2px;
    position: absolute;
    top: 8px;
    left: -4px;
    width: 152px;
    height: 30px;
    line-height: 30px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-size: 16px;
}
.logo_right {
    position: absolute;
    top: -8px;
    right: 20px;
}
.mulu_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
}

.item {
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    color: rgba(1, 1, 1, 1);
    height: 36px;
    margin: 0 12px;
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    padding-top: 16px;
}
.item:nth-child(4){
    height: 60px;
}
</style>