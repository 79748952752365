import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        dataShow: '1'
    },
    mutations: {
        PUSH_DATA: (state,action)=>{
            state.dataShow = action;
        }
    },
    actions: {}
})